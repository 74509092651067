import Image from "next/image";
import { constants } from "~/common/constants";
import { Headline, Link } from "~/ui-library";

export type HomepageHeroProps = {
  imgSrc: string;
  imgAlt: string;
  headline: string;
  headlineHref: string;
  body: string;
  relatedUrl: string;
  relatedText: string;
  secondaryTitle: string;
};

export const HomepageHero: React.FC<HomepageHeroProps> = ({
  imgSrc,
  imgAlt,
  headline,
  headlineHref,
  body,
  relatedUrl,
  relatedText,
  secondaryTitle,
}: HomepageHeroProps) => (
  <div className="grid grid-cols-1 gap-m md:grid-cols-2 lg:grid-cols-3 xl:gap-l">
    <div className="md:col-span-1 lg:col-span-2">
      <Headline
        level={6}
        as="h2"
        className="mb-m uppercase tracking-headline1-block text-gray-800"
      >
        <span>{secondaryTitle}</span>
      </Headline>
      <Image
        src={imgSrc}
        alt={imgAlt}
        quality={100}
        fill
        className="!relative !h-[unset] w-full"
      />
    </div>
    <div className="md:col-span-1 md:mt-xl">
      <div className="mb-s border-b-1 border-gray-300 pb-s md:mb-l">
        <Link
          href={headlineHref}
          data-event-tree-target={
            constants.EventTreeTarget.CONTENT_ITEM_HEADLINE_LINK
          }
          data-analytics-headline={headline}
          className="text-gray-900"
        >
          <Headline as="h2" level={3} mdLevel={2} className="pb-s">
            {headline}
          </Headline>
        </Link>
        <p className="text-gray-800">{body}</p>
      </div>
      <div>
        <Link
          className="!block text-body-small font-semibold "
          href={relatedUrl}
          data-event-tree-target={
            constants.EventTreeTarget.CONTENT_ITEM_HEADLINE_LINK
          }
          data-analytics-headline={relatedText}
        >
          <span className="tracking-headline6-block text-gray-800">
            RELATED:{" "}
          </span>
          <span className="text-black">{relatedText}</span>
        </Link>
      </div>
    </div>
  </div>
);
