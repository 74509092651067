import { VariantProps, cva, cx } from "cva";

const contentWrapperStyle = cva("relative bg-white md:mx-[auto]", {
  variants: {
    overlay: {
      true: "bg-primary-50 md:bg-transparent",
      /* Using ring (solid drop shadow) rather than borders to ensure adjacent 
      borders collapse. If tailwind supports the adjacent sibling combinator 
      in the future, that might be preferable. */
      false: "bg-white ring-gray-300 md:ring-1",
    },
    containerWidth: {
      848: "max-w-[848px]",
      960: "max-w-[960px]",
      1312: "max-w-[1312px]",
    },
    hasBackground: {
      true: "z-10",
      false: "z-20",
    },
    fullWidth: {
      true: "w-full",
      false: "",
    },
  },
});

/* This works by creating an absolute positioned element that is 100% of it's 
containers height + 6rem and 100% of it's containers width. The element is
positioned with top -3rem so that it creates a offset that overflows the y axis.
The background image is hidden at small breakpoints without an overflowing 
background, so we can simply hide this component on mobile. */
const backgroundImageStyle = cva(
  ["hidden", "h-full", "w-full", "absolute", "z-10", "md:py-l"],
  {
    variants: {
      hasBackground: {
        // Sets the background to display at medium and makes the background image width 50% of its container
        true: ["md:block", "bg-no-repeat", "md:bg-[length:auto_100%]"],
        false: "",
      },
      backgroundPosition: {
        left: "bg-left",
        right: "bg-right",
      },
    },
  }
);

type BackgroundLayerProps = {
  backgroundUrl?: string;
  backgroundPosition?: "left" | "right";
};

const BackgroundLayer: React.FC<BackgroundLayerProps> = ({
  backgroundUrl,
  backgroundPosition = "right",
}) => (
  <div
    className={backgroundImageStyle({
      hasBackground: Boolean(backgroundUrl),
      backgroundPosition,
    })}
    style={backgroundUrl ? { backgroundImage: `url("${backgroundUrl}")` } : {}}
  />
);

export type SectionWrapperProps = {
  children: React.ReactNode;
  overlay?: boolean;
  className?: string;
} & BackgroundLayerProps &
  VariantProps<typeof contentWrapperStyle>;

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
  containerWidth = 1312,
  overlay = false,
  backgroundUrl,
  backgroundPosition,
  className,
  fullWidth,
}) => (
  <section
    className={cx(
      "relative w-full md:px-s",
      backgroundUrl ? "bg-primary-50" : "",
      className
    )}
  >
    <div
      className={contentWrapperStyle({
        overlay,
        containerWidth,
        fullWidth,
        hasBackground: Boolean(backgroundUrl),
      })}
    >
      {overlay ? (
        <BackgroundLayer
          backgroundUrl={backgroundUrl}
          backgroundPosition={backgroundPosition}
        />
      ) : null}
      <div>{children}</div>
    </div>
    {overlay ? (
      <div className="absolute inset-0 -top-[3rem] z-0 hidden h-[calc(100%+6rem)] w-full bg-primary-50 md:block" />
    ) : null}
  </section>
);
