import { Latest, PostItemWithMeta } from "~/types/asgard/Homepage";
import { getKickerHref } from "~/lib/shared/getKickerHref";
import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { getRouteFromPostType } from "~/lib/shared/getRouteFromPostType";
import { HomepageTabbedGridSectionProps } from "./HomepageTabbedGridSection";

const postDataToContentItemWithImage = (postData: PostItemWithMeta) => ({
  id: postData.post.id?.toString(),
  imageSrc: postData.post.image?.source || "",
  imageAlt: heDecodeOrDefault(postData.post.image?.altText),
  kicker: heDecodeOrDefault(postData.post.primaryCategory?.name),
  kickerHref: getKickerHref(postData.post.primaryCategory?.slug),
  headline: heDecodeOrDefault(postData.post.title),
  headlineHref: getRouteFromPostType(postData.post.type, postData.post.slug),
  footer: heDecodeOrDefault(postData.metadata),
  date: postData.post.dateGmt,
});

export const apiToHomepageTabbedGridSectionProps = (
  latestData: Latest
): HomepageTabbedGridSectionProps => ({
  sectionHeader: heDecodeOrDefault(latestData.title),
  sectionBody: heDecodeOrDefault(latestData.copy),
  taxonomyTabs: latestData.taxonomy.map((tabData) => ({
    title: heDecodeOrDefault(tabData.title),
    featuredPost: postDataToContentItemWithImage(tabData.featuredPost),
    secondaryPosts: tabData.secondaryPosts.map(postDataToContentItemWithImage),
  })),
});
