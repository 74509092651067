import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { AllItems } from "../../components/Layout/menuData";

/**
 * Utility function that will return a link for a given slug by comparing
 * the slug to known routes used in the menu in the application.
 *
 * @returns the in-app route for the category, or null if
 * either no route could be determined or the slug is empty
 */
export function getKickerHref(
  category: string | undefined | null
): string | null {
  if (!category) {
    return null;
  }

  const menuRoutes = AllItems.map((item) => item.link).filter(Boolean);

  const knownRoute = menuRoutes.find((route) =>
    route.toLowerCase().includes(category.toLowerCase())
  );

  return knownRoute ? heDecodeOrDefault(knownRoute, null) : null;
}
