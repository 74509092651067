import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { Subscribe } from "~/types/asgard/Homepage";
import { HomepageListCtaSectionProps } from "./HomepageListCtaSection";

export const apiToHomepageListCtaSectionProps = (
  subscribeData: Subscribe
): HomepageListCtaSectionProps => ({
  hasAccess: false,
  title: heDecodeOrDefault(subscribeData.title),
  listItems: subscribeData.valueProps.map(({ valueProp }) =>
    heDecodeOrDefault(valueProp)
  ),
  backgroundUrl: subscribeData.backgroundImage.source,
  cta: subscribeData.valuePropCta.title,
  ctaLink: subscribeData.valuePropCta.url,
});
