import { Tab } from "@headlessui/react";
import { cx } from "cva";
import React, { useState } from "react";
import { constants } from "~/common/constants";
import { ContentItemGrid } from "~/components/ContentItemGrid";
import {
  ContentItemWithImage,
  ContentItemWithImageProps,
} from "~/components/ContentItemWithImage";
import { SectionWrapper } from "~/components/SectionWrapper";
import { Body, Pill } from "~/ui-library";
import { Headline } from "~/ui-library/components/Headline/Headline";

export type HomepageTabbedGridSectionProps = {
  sectionHeader: string;
  sectionBody: string;
  taxonomyTabs: HomepageTabGroup[];
};

export type HomepageTabGroup = {
  title: string;
  featuredPost: ContentItemWithImageProps;
  secondaryPosts: ContentItemWithImageProps[];
};

type TabGroupProps = {
  group: HomepageTabGroup;
};

const TaxonomyPosts = ({ group }: TabGroupProps) => (
  <div
    className={cx([
      "grid grid-cols-1 gap-m",
      "lg:grid-cols-2 lg:grid-rows-[repeat(2,min-content)] lg:gap-0",
    ])}
  >
    {/* Featured Post Component */}
    <div className={cx(["border-b-1 border-gray-300 pb-m", "md:pb-l lg:mr-s"])}>
      <ContentItemWithImage
        asHero
        imagePosition="top"
        {...group.featuredPost}
      />
    </div>

    {/* Secondary Grid */}
    <div
      className={cx([
        "border-b-1 border-gray-300 pb-l",
        "md:mt-l",
        "lg:col-span-1 lg:row-span-2 lg:mt-0 lg:border-b-0 lg:border-l-1 lg:pb-0 lg:pl-s",
      ])}
    >
      <ContentItemGrid itemBorder={false} variant={"4-item-grid-to-column"}>
        {group.secondaryPosts.map((post, innerIndex) => (
          <ContentItemWithImage
            imagePosition="top"
            key={innerIndex}
            {...post}
          />
        ))}
      </ContentItemGrid>
    </div>

    {/* CTA Component */}
    <div className="pb-xl md:pb-0 lg:pr-s lg:pt-m"></div>
  </div>
);

export const HomepageTabbedGridSection: React.FC<
  HomepageTabbedGridSectionProps
> = ({
  sectionHeader,
  sectionBody,
  taxonomyTabs,
}: HomepageTabbedGridSectionProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <SectionWrapper>
      <div className={cx(["p-s py-xl", "md:p-m md:py-xl"])}>
        <div className="mx-[auto] flex max-w-[640px] flex-col gap-xs md:items-center">
          <Headline as="h2" level={3} mdLevel={2}>
            {sectionHeader}
          </Headline>
          <Body className="pb-m text-gray-800">{sectionBody}</Body>
        </div>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="mx-[auto] mb-xl flex w-full max-w-[640px] flex-wrap gap-s md:justify-center">
            {taxonomyTabs.map((item, index) => (
              <Tab
                className="outline-offset-2 outline-brand"
                key={index}
                data-event-tree-target={
                  constants.EventTreeTarget.HOMEPAGE_TABBED_GRID_BUTTON
                }
              >
                {index === selectedIndex ? (
                  <Pill appearance="selected">{item.title}</Pill>
                ) : (
                  <Pill>{item.title}</Pill>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {taxonomyTabs.map((item, index) => (
              <Tab.Panel tabIndex={-1} key={index}>
                <TaxonomyPosts group={item} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </SectionWrapper>
  );
};
