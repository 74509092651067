import { constants } from "~/common/constants";
import { SectionWrapper } from "~/components/SectionWrapper";
import { Headline, Icon, Link } from "~/ui-library";
import { ArrowRightCircleIcon } from "~/ui-library/icons";
import { buttonStyles } from "~/ui-library/shared-styles/shared-button-styles";

export type HomepageListCtaSectionProps = {
  hasAccess: boolean;
  title: string;
  listItems: string[];
  backgroundUrl: string;
  cta: string;
  ctaLink: string;
};

export const HomepageListCtaSection: React.FC<HomepageListCtaSectionProps> = ({
  title,
  listItems,
  hasAccess,
  backgroundUrl,
  cta,
  ctaLink,
}) => {
  if (hasAccess) {
    return null;
  }

  return (
    <SectionWrapper overlay={true} backgroundUrl={backgroundUrl}>
      <div className="relative z-20 p-s py-xl md:p-m md:py-xxl">
        <Headline level={0} as="h3" className="mb-l hidden w-1/2 lg:block">
          {title}
        </Headline>
        <Headline level={3} as="h3" className="mb-l md:w-2/3 lg:hidden">
          {title}
        </Headline>
        <ul className="md:w-2/3 lg:w-1/2">
          {listItems.map((item, idx) => (
            <li className="mb-m flex last:mb-l md:last:mb-xl" key={idx}>
              <Icon
                className="mr-xs fill-transparent"
                width="32px"
                SvgIcon={ArrowRightCircleIcon}
                title={"list icon"}
              />
              <div>{item}</div>
            </li>
          ))}
        </ul>
        <Link
          className={buttonStyles({
            fullWidth: true,
            variant: "primary",
            size: "default",
            className: "flex items-center justify-center md:w-[253px]",
          })}
          href={ctaLink}
          data-event-tree-target={
            constants.EventTreeTarget.HOMEPAGE_SUBSCRIPTION_BLOCK
          }
        >
          {cta}
        </Link>
      </div>
      <div className="absolute top-0 z-10 size-full bg-transparent bg-gradient-to-r from-primary-50 via-primary-50"></div>
    </SectionWrapper>
  );
};
