import { cx } from "cva";
import { HomepageHero, HomepageHeroProps } from "~/components/HomepageHero";
import { ContentItemGrid } from "~/components/ContentItemGrid";
import {
  ContentItemWithImage,
  ContentItemWithImageProps,
} from "~/components/ContentItemWithImage";
import { ContentItem, ContentItemProps } from "~/components/ContentItem";
import {
  HomepageSearchOrCTA,
  HomepageSearchOrCTAProps,
} from "~/components/HomepageSearchOrCTA";
import { SectionWrapper } from "~/components/SectionWrapper";
import { Headline } from "~/ui-library";

export type HomepageHeaderSectionProps = {
  homepageHeroProps: HomepageHeroProps;
  secondaryItems: ContentItemWithImageProps[];
  sidebarTitle: string;
  sidebarItems: ContentItemProps[];
  homepageSearchOrCTAProps: HomepageSearchOrCTAProps;
};

export const HomepageHeaderSection: React.FC<HomepageHeaderSectionProps> = ({
  homepageHeroProps,
  secondaryItems,
  sidebarTitle,
  sidebarItems,
  homepageSearchOrCTAProps,
}) => (
  <>
    {/* provides gray line behind mobile site h1 */}
    <div className="relative -bottom-[2.5em] px-s md:hidden">
      <div className="border-t-1 border-gray-300"></div>
    </div>
    <Headline
      level={6}
      as="h1"
      blockCase
      className="relative z-30 mt-[1.5em] w-full px-s text-center text-gray-700 md:-bottom-[.7em] md:m-0"
    >
      <span className="inline-flex flex-col bg-white px-s text-[12px] md:flex-row md:gap-[.2em] md:px-l">
        <span>Data&nbsp;&&nbsp;Analysis&nbsp;FROM</span>
        <span>Morning&nbsp;Consult&nbsp;</span>
      </span>
    </Headline>
    <SectionWrapper className="z-20">
      <div className="flex flex-col lg:grid lg:grid-cols-[3fr,1fr]">
        {/* large breakpoint column 1 */}
        <div className="contents lg:flex lg:flex-col">
          {/* hero */}
          <div className={cx(["order-1 p-s py-l", "md:p-m"])}>
            <HomepageHero {...homepageHeroProps} />
          </div>
          {/* three item with images row */}
          <div className="order-2 hidden px-s md:block md:px-m">
            <div className="border-t-1 border-gray-300"></div>
          </div>
          <div
            className={cx([
              "order-3 px-s pb-l",
              "md:p-m md:py-l",
              "lg:order-2",
            ])}
          >
            <ContentItemGrid
              variant="3-item-row"
              titleBorder={false}
              itemBorder={false}
            >
              {secondaryItems.map(({ id, ...itemProps }) => (
                <ContentItemWithImage {...itemProps} key={id} />
              ))}
            </ContentItemGrid>
          </div>
        </div>
        {/* end of column 1 */}

        {/* large breakpoint column 2 */}
        <div className="contents lg:flex lg:flex-col lg:border-l-1 lg:border-gray-300">
          {/* text content item list */}
          <div
            className={cx([
              "order-4 flex-grow border-t-1 border-gray-300 p-s pb-xl pt-l",
              "md:border-t-1 md:p-m",
              "lg:order-1 lg:border-0",
            ])}
          >
            <ContentItemGrid
              variant="1-column-to-2-column-med-only"
              titleBorder={false}
              title={sidebarTitle}
              itemBorder={false}
            >
              {sidebarItems.map(({ id, ...itemProps }) => (
                <ContentItem {...itemProps} key={id} />
              ))}
            </ContentItemGrid>
          </div>

          {/* CTA */}
          <div
            className={cx([
              "order-2 m-s mb-l px-0",
              "md:m-0 md:my-s md:px-m lg:pb-l",
              "lg:order-2",
            ])}
          >
            <div
              className={cx([
                "block h-[1px] bg-gray-300 md:hidden lg:block",
                "mb-l mt-0 lg:mt-m",
              ])}
            ></div>
            <HomepageSearchOrCTA {...homepageSearchOrCTAProps} />
          </div>
        </div>
        {/* end of column 2 */}
      </div>
    </SectionWrapper>
  </>
);
