import { Hero } from "~/types/asgard/Homepage";
import { getKickerHref } from "~/lib/shared/getKickerHref";
import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { getRouteFromPostType } from "~/lib/shared/getRouteFromPostType";
import { HomepageSearchOrCTAProps } from "~/components/HomepageSearchOrCTA";
import { HomepageHeaderSectionProps } from "./HomepageHeaderSection";

export const apiToHomepageHeaderSectionProps = (
  heroData: Hero
): HomepageHeaderSectionProps => ({
  homepageHeroProps: {
    imgSrc: heroData.featuredPost.post.image?.source || "",
    imgAlt: heDecodeOrDefault(heroData.featuredPost.post.image?.altText),
    headline: heDecodeOrDefault(heroData.featuredPost.post.title),
    headlineHref: getRouteFromPostType(
      heroData.featuredPost.post.type,
      heroData.featuredPost.post.slug
    ),
    body: heDecodeOrDefault(heroData.featuredPost.post.subhead),
    relatedUrl: getRouteFromPostType(
      heroData.relatedPost.post.type,
      heroData.relatedPost.post.slug
    ),
    relatedText: heDecodeOrDefault(heroData.relatedPost.post.title),
    secondaryTitle: heDecodeOrDefault(heroData.secondaryTitle),
  },
  secondaryItems: heroData.secondaryPosts.map((postItemWithMeta) => ({
    id: postItemWithMeta.post.id.toString(),
    imagePosition: "top",
    imageSrc: postItemWithMeta.post.image?.source || "",
    imageAlt: postItemWithMeta.post.image?.altText || "",
    kicker: heDecodeOrDefault(postItemWithMeta.post.primaryCategory?.name),
    kickerHref: getKickerHref(postItemWithMeta.post.primaryCategory?.slug),
    headline: heDecodeOrDefault(postItemWithMeta.post.title),
    headlineHref: getRouteFromPostType(
      postItemWithMeta.post.type,
      postItemWithMeta.post.slug
    ),
    footer: postItemWithMeta.metadata,
    date: postItemWithMeta.post.dateGmt,
  })),
  sidebarTitle: heDecodeOrDefault(heroData.sidebarTitle),
  sidebarItems: heroData.sidebarPosts.map((postItemWithMeta) => ({
    id: postItemWithMeta.post.id.toString(),
    headline: heDecodeOrDefault(postItemWithMeta.post.title),
    headlineHref: getRouteFromPostType(
      postItemWithMeta.post.type,
      postItemWithMeta.post.slug
    ),
  })),
  homepageSearchOrCTAProps: {
    authHeadline: heroData.searchscribe.authenticatedTitle,
    unauthHeadline: heroData.searchscribe.unauthenticatedTitle,
    authBody: heroData.searchscribe.authenticatedCopy,
    authButtonText: heroData.searchscribe.authenticatedCta.title,
    authButtonURL: heroData.searchscribe.authenticatedCta.url,
    unauthBody: heroData.searchscribe.unauthCopy,
    unauthButtonText: heroData.searchscribe.unauthenticatedCta.title,
    unauthButtonURL: heroData.searchscribe.unauthenticatedCta.url,
    hasAccess: false,
  } satisfies HomepageSearchOrCTAProps,
});
