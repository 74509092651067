import Link from "next/link";
import { Body, Headline } from "~/ui-library";
import { buttonStyles } from "~/ui-library/shared-styles/shared-button-styles";
import { SparklesIcon } from "@heroicons/react/20/solid";
import { constants } from "~/common/constants";

type CtaDataProps = {
  body: string;
  headline: string;
  buttonText: string;
  buttonURL: string;
  eventName: string;
};

export type HomepageSearchOrCTAProps = {
  authHeadline: string;
  unauthHeadline: string;
  authBody: string;
  authButtonText: string;
  authButtonURL: string;
  unauthBody: string;
  unauthButtonText: string;
  unauthButtonURL: string;
  hasAccess: boolean;
};

const SearchOrCTA: React.FC<CtaDataProps> = ({
  headline,
  body,
  buttonURL,
  buttonText,
  eventName,
}) => (
  <div>
    <div className="flex flex-wrap items-center justify-between gap-s">
      <div className="flex size-m items-center justify-center rounded-full bg-black">
        <SparklesIcon width={12} height={12} color="white" />
      </div>
      <div className="w-full md:w-2/3 lg:w-full">
        <Headline level={4} className="pb-xs">
          {headline}
        </Headline>
        <Body size="small">{body}</Body>
      </div>
      <div className="block w-full md:w-1/4 lg:w-full">
        <Link
          className={buttonStyles({
            fullWidth: true,
            variant: "primary",
            size: "default",
            className: "flex items-center justify-center",
          })}
          href={buttonURL}
          data-event-tree-target={eventName}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  </div>
);

export const HomepageSearchOrCTA: React.FC<HomepageSearchOrCTAProps> = ({
  authBody,
  unauthBody,
  authButtonText,
  authButtonURL,
  unauthButtonText,
  unauthButtonURL,
  authHeadline,
  unauthHeadline,
  hasAccess,
}) =>
  hasAccess ? (
    <SearchOrCTA
      body={authBody}
      buttonText={authButtonText}
      buttonURL={authButtonURL}
      headline={authHeadline}
      eventName={constants.EventTreeTarget.HOMEPAGE_SEARCH_OR_CTA_AUTHENTICATED}
    />
  ) : (
    <SearchOrCTA
      body={unauthBody}
      buttonText={unauthButtonText}
      buttonURL={unauthButtonURL}
      headline={unauthHeadline}
      eventName={
        constants.EventTreeTarget.HOMEPAGE_SEARCH_OR_CTA_UNAUTHENTICATED
      }
    />
  );
